import React from "react";
import Container from "react-bootstrap/Container";
import ReactRotatingText from 'react-rotating-text'
import './TextRotator.css'
const TextRotator = (props) => {
  return (
      <ReactRotatingText items={props.data}></ReactRotatingText>
  );
};

export default TextRotator;
