import React from "react";

import ContactUs from "../components/Contact/ContactUs";

const Contact = (props)=> {
    return (
     
            <ContactUs />
       
    )
}



export default Contact;