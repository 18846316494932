import React,{useRef, useEffect} from "react";
import {gsap} from 'gsap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SimpleSlider from './SkillSlider'

import './AboutUs.scss';
import TextRotateIn from "./../portfolio/TextRotateIn";
import TextRotator from "./../Home/TextRotator"

const sliderData = [
  {
    "title":"Brand Identities", 
    "desc":"Visual identity design from research through to implementation.",
    "icon":"/images/about/icons/Branding-Icon.png"
  },
  {"title":"Digital Design", "desc":"Website  design, mobile design, social media design, and newsletter design.",
  "icon":"/images/about/icons/Art-Direction-Icon.png"},
  {"title":"Art Direction", "desc":"Visual concepts for editorial photoshoots, e-com imagery, illustration and animation.",
  "icon":"/images/about/icons/3icon.png"},
  {"title":"Product Design", "desc":"Concept and design for physical products and custom packaging.",
  "icon":"/images/about/icons/4icon.png"},
  {"title":"Print Design", "desc":"Packaging, printed brand collateral, editorial and book design, and print production.",
  "icon":"/images/about/icons/5icon.png"},
  {"title":"Illustration", "desc":"Illustration and pattern design for identities, packaging and campaigns.",
  "icon":"/images/about/icons/Print-design-Icon.png"}
]
const AboutUs = (props) => {

  const gs = useRef(null);

  const words = ["<img src=\"/images/about/slidetxt/impactful.svg\">","<img src=\"/images/about/slidetxt/design.svg\">","<img src=\"/images/about/slidetxt/that.svg\">","<video playsinline  loop  autoPlay=\"autoplay\" muted><source src=\"/images/about/slidetxt/works.mp4\" type=\"video/mp4\" /></video>"]

  const handleClick = (e)=>{
    gsap.to(e.target,{rotation:'50',yoyo:true, repeat:1})
  }

  useEffect(()=>{
    let ctx = gsap.context(()=>{
        gsap.to('.square',{rotate:360, duration:5})
    },gs);

    return ()=>ctx.revert();
  },[])
  return (
    <section className="about-us" ref={gs}>
        <div className="about-us-banner">
          <Container>
            <Row className="about-us-banner-inner">
              <Col>
                <h1><span>Listening to and Understanding </span><br/><span>Your <TextRotator data={["WANTS","NEEDS","DESIRES"]}/></span></h1>
              </Col>
             
            </Row>
          </Container>
        </div>
       
        <h2 className="impact">
        {words.map((word)=>
          <TextRotateIn data={word}></TextRotateIn>
        )}
        </h2>
          <div className="what-i-do">
            <Container>
              <Row className="what-i-do-inner">
              
                <Col md="5">
                  <h1><span>What</span><br/> I do</h1>
                  <p>I leverage the strength of creative problem-solving to integrate intricate graphic design elements into purposefully crafted authentic visual narratives.
</p>
                </Col>
                <Col md="1">&nbsp;</Col>
                <Col md="6" className="image-holder"><img src="/images/about/what-i-do.gif" /></Col>
               
              </Row>
            </Container>
            </div>
            <div className="how-can-i-help">
              <Container>
                <Row>
                <Col md="1"></Col>
                  <Col md="4">
                    <h2><span>HOW</span><br/> CAN</h2>
                    <p>I bring extensive expertise in web and brand design, driven by a fervor for crafting distinctive and enduring masterpieces tailored for ambitious businesses., entrepreneurs and artists.</p>
                  </Col>
                  <Col md="2"></Col>
                  <Col md="4">
                  <h2 className="righty">I Help<br/> You</h2>
                  <p>My mission  is to drive outstanding growth for my clients, enhancing their brand presence, shaping their identity, and captivating customers through compelling aesthetics.</p>
                  </Col>
                  <Col md="1"></Col>
                </Row>
                
                <hr className="rule"/>
                <Row>
                  <Col md="1"></Col>
                  <Col md="10">
                    <SimpleSlider data={sliderData} />
                    </Col>
                  <Col md="1"></Col>
                
                </Row>
              </Container>
            </div>
            <div className="my-skills">

            <Container>
              <div  className="my-skills-inner" style={{backgroundImage:'url("/images/about/myskills.gif")'}}>
              <Row><h1><span>My</span><br/> SKILLS</h1></Row>
              <Row>
              
                <Col  xs="7" sm="6" md="5">
                  <p>
                    <strong>PERSONAL</strong><br/>
                      Intuition, Imagination, Discipline, Honesty, Communication, Punctuality
                      </p><p>
                      <strong>PROFESSIONAL</strong><br/>
                      Time Management, Multitasking, Creativity, Photoshop, After Effects, Illustrator, XD, In Design
                      </p><p>
                      <strong>ADDITIONAL</strong><br/>
                      Animation, AI Tools, Business Development, Branding, Marketing and SEO

                      </p>
                </Col>
                <Col md="1">&nbsp;</Col>
                <Col  xs="4" sm="5" md="6">&nbsp;</Col>
                
               
              </Row>
              </div>
            </Container>
          </div>

    </section>
  );
};

export default AboutUs;
