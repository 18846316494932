import React from "react";
import Home from "./Home";
import About from "./About";
import CaseStudiesIndex from "./CaseStudyIndex";
import Contact from "./Contact";


const routes = [
    { path: '/', component: <Home />,connect:false},
    { path: '/about', component: <About />,connect:false},
    { path: '/portfolio', component: <CaseStudiesIndex />,connect:false},
    { path: '/contact', component: <Contact />,connect:true},
    // projects are also added in App.js!
]

export default routes;
