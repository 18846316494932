
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

import routes from './pages/router';
import { useParams } from 'react-router';

import {connect} from 'react-redux';
import './App.scss';
import { configSelector } from "./redux/selectors";
import Header from "./components/Header";
import Footer from "./components/Footer";

import CaseStudies from "./pages/CaseStudy";
import portfolio from './assets/data/portfolio.json';
import ScrollToTop from "./components/scrollToTop";

window.Buffer = window.Buffer || require("buffer").Buffer;

const projectsMap = portfolio.items.filter((item)=>item.name!=="demo"&&item.name!=="portfolio").map((item,i)=>{
  console.log(portfolio.items.length, i)
  return {
      path:`/portfolio/${item.slug}`,
      component: <CaseStudies {...item} nextLinkSlug={i===(portfolio.items.filter((item)=>item.name!=="demo"&&item.name!=="portfolio").length-1)?portfolio.items.filter((item)=>item.name!=="demo"&&item.name!=="portfolio")[0].slug:portfolio.items.filter((item)=>item.name!=="demo"&&item.name!=="portfolio")[i+1].slug} />,
      connect:false
  }
})

function App() {
  const {name} = useParams();
  return (

      <Router>
       <ScrollToTop />
        <Header />
          <Routes>
            {
            routes.map((data,index) => (
             
                <Route exact={true} path={data.path} element={data.component} key={index} />
       
            ))
            }

{
            projectsMap.map((data,index) => (
             
                <Route exact={true} path={data.path} element={data.component} key={index} />
       
            ))
            }

            
        </Routes>
            <Footer/>
      </Router>
  );
}

function mapStateToProps(state){
	return {
    config:configSelector(state),
	}
}

export default connect(mapStateToProps)(App);
