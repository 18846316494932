import React, { Component } from "react";
import Slider from "react-slick";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './SkillSlider.scss';

export default class SimpleSlider extends Component  {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows:false,
      slidesToShow: 2,
      slidesToScroll: 2,
      "responsive": [
        {
          "breakpoint": 1024,
          "settings": {
            dots: true,
            infinite: true,
            speed: 500,
            arrows:false,
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          "breakpoint": 667,
          "settings": {
            dots: true,
      infinite: true,
      speed: 500,
      arrows:false,
      slidesToShow: 1,
      slidesToScroll: 1,
           
          }
        },
        {
          "breakpoint": 480,
          "settings": {
            dots: true,
            infinite: true,
            speed: 500,
            arrows:false,
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
    ]
    };
    return (
      <div>
        <Slider {...settings}>
          { this.props.data.map((item)=>
            <div className="slider-cell">
              <Row>
                <Col xs={4} sm={4} md={4} lg={3} xl={2}><img className="skill-icon" src={item.icon} alt={item.title} title={item.title} /></Col><Col xs={8} sm={8}  md={8} lg={9} xl={10}><h5>{item.title}</h5><p>{item.desc}</p></Col></Row></div>
          )}
        </Slider>
      </div>
    );
  }
}
