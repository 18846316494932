import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"; 
import Carousel from '../portfolio/Carousel'
import Filmstrip from '../portfolio/Filmstrip'
import TallScroller from '../portfolio/TallScroller'
import './PortfolioBlock.scss'

const PortfolioBlock = (props) => {

    const renderPortfolioBlock = (row)=>{
      switch (row.type) {
        case "single":
          return  <Container className="single-row">

                    {row.items.map((item, i)=>
                      <Row key={i}>
                        <Col md="12">
                          <div className={`image-tile ${row.bordered?'bordered':'un-bordered'}`}>
                          
                            {item.label && (
                              <h3>{item.label}</h3>
                            )}
                          <img src={item.img} alt={item.alt} title={item.alt} />
                          {item.caption && (
                              <div className="image-title-caption" dangerouslySetInnerHTML={{ __html: item.caption }}>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    )}
                    </Container>
          break;
          case "multi":
            return  <Container  className="multi-row">
                     <div className={`image-tile ${row.bordered?'bordered':'un-bordered'}`}>
                     <Row style={row.bg?{backgroundColor:`${row.bg}`}:{}}>
                     
                      {row.items.map((item, i)=>
                       
                          <Col md={item.size}>
                           
                              {item.label && (
                                <h3>{item.label}</h3>
                              )}
                            <img src={item.img} alt={item.alt} title={item.alt} />
                            {item.caption && (
                                <div className="image-title-caption" dangerouslySetInnerHTML={{ __html: item.caption }}>
                                </div>
                              )}
                            
                          </Col>
                        
                      )}
                      
                      </Row>
                      </div>
                      </Container>
            break;

            case "grid":
            return  <Container  className="grid-row">
                     <Row>
                     
                      {row.items.map((item, i)=>
                       
                          <Col md={item.size}>
                           <div  className={`image-tile ${row.bordered?'bordered':'un-bordered'}`}>
                              {item.label && (
                                <h3>{item.label}</h3>
                              )}
                               { item.img.includes('.mp4')? (
                                  <video  muted autoplay playsInline  autoPlay="autoplay"><source src={item.img} type="video/mp4" /></video>
                                ):(
                                  <img src={item.img} alt={item.alt} title={item.alt} />
                                )}
                            {item.caption && (
                                <div className="image-title-caption" dangerouslySetInnerHTML={{ __html: item.caption }}>
                                </div>
                              )}
                            </div>
                          </Col>
                        
                      )}
                      
                      </Row>
                      </Container>
            break;
            case "carousel":
              return  <Container  >
                      <Row>
                        <Col>
                        <div  className={`carousel image-tile ${row.bordered?'bordered':'un-bordered'}  ${row.settings.dots?'has-dots':'has-no-dots'}`}>
                          <div style={row.bg?{backgroundColor:`${row.bg}`}:{}}>
                      <Carousel  items={row.items} settings={row.settings} />
                      </div>
                      </div>
                      </Col></Row>
                    </Container>
            break;

            case "video-block":
              return  <Container>
                      <Row>
                        <Col>
                        <div className={`video-block image-tile ${row.bordered?'bordered':'un-bordered'}`}>
                      <video playsInline key={row.items[0].src}  loop  autoPlay="autoplay" muted><source src={row.items[0].src} type={row.items[0].mimeType} /></video>
                      
                      </div>
                      </Col>
                      </Row>
                    </Container>
            break;

            case "filmstrip":
            return  <Filmstrip data={row} />
            break;
            case "tall-scroller":
            return  <TallScroller data={row} />
            break;
            case "text-block":
            return  <Container>
              <Row>
                        <Col>
              <div>
                <Row  className="burb" >
                
                <Col xs="12" md="6"><h4>{row.title}</h4></Col>
                <Col xs="12" md="6">
                <p>{row.desc}</p>
                {row.descExt && (
                  <p>{row.descExt}</p>
                )}
                {row.descExt2 && (
                  <p>{row.descExt}</p>
                )}
                </Col>
              </Row>
              </div>
            </Col></Row>
          </Container>
            break;
      
        default:
          break;
      }
    }
  return renderPortfolioBlock(props.row);
};

export default PortfolioBlock;
