import React from "react";
import Container from "react-bootstrap/Container";

import "./ContactLink.css";
import { Link } from 'react-router-dom' 
import TextRotator from './TextRotator'
import Button from 'react-bootstrap/Button'
import contactIcon from './../../assets/images/contact-link.png'
const ContactLink = (props) => {
  return (

       <Link className="contact-link" to={props.data.link}>
        <Container>
          <span>
            <span dangerouslySetInnerHTML={{ __html: props.data.labelPre }}></span> <TextRotator data={  props.data.labelRotating}/><span dangerouslySetInnerHTML={{ __html: props.data.labelPost}}>
            </span>
            </span>
            <span className="aside"><Button>{props.data.aside}</Button></span>
        </Container>
        </Link>

  );
};

export default ContactLink;
