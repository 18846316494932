import React from "react";
import './CaseStudies.scss';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from 'react-router-dom' 
import PortfolioBlock from './PortfolioBlock'
import portfolio from './../../assets/data/portfolio'
import SplashPanel from './../portfolio/SplashPanel'

const CaseStudyIndex = (props) => {


  return (
    <>
    <SplashPanel className="no-tags" data={portfolio.items.filter((item)=>item.name ==="portfolio")[0].headline}/>
    <section className="case-studies">
        
        <Container>
        {/* <div>
            <Row>
                <Col>
                <Container className="portfolio-intro">
                <h4 dangerouslySetInnerHTML={{__html:portfolio.items.filter((item)=>item.name ==="portfolio")[0].desc}}></h4>
                <p dangerouslySetInnerHTML={{__html:portfolio.items.filter((item)=>item.name ==="portfolio")[0].descExt}}></p>
            </Container></Col>
            </Row>
        </div> */}

        {portfolio.items.filter((item)=>item.name ==="portfolio")[0].rows.map((rowData,i)=>
            <div key={i}>
              <PortfolioBlock row={rowData} />
            </div>
          )}


            
                 {portfolio.items.filter((item)=>item.name !=="demo" && item.name !=="portfolio").slice(0,1).map((project,i)=>
               
                  <Row key={i}>
                      <Col>
                          <div className="cast-study-item case-study-item-full">
                              <Link to={`/portfolio/${project.slug}`}>
                                  <h2>{project.name}</h2>
                              </Link>
                              <Link  to={`/portfolio/${project.slug}`}>
                                  <img src={project.splashImg} />
                              </Link>
                              <p>{project.blurb}</p>
                              <Link className="view-more-button" to={`/portfolio/${project.slug}`}>VIEW</Link>
                          </div>
                      </Col>
                  </Row>
              )} 

            <Row>
                {portfolio.items.filter((item)=>item.name !=="demo" && item.name !=="portfolio").slice(1).map((project,i)=> 
                <Col xs={12} sm={12} md={6} key={i}>
                <div className="cast-study-item case-study-item-full">
                    <Link to={`/portfolio/${project.slug}`}>
                        <h2>{project.name}</h2>
                    </Link>
                    <Link  to={`/portfolio/${project.slug}`}>
                        <img src={project.splashImg} />
                    </Link>
                    <p>{project.blurb}</p>
                    <Link className="view-more-button" to={`/portfolio/${project.slug}`}>VIEW</Link>
                </div>
            </Col>
                )} 
            </Row> 
       
            
        </Container>
      
    </section>
    </>
  );
};

export default CaseStudyIndex;
