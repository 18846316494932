import React from "react";
import Container from "react-bootstrap/Container";
import TextRotator from "./TextRotator"

import "./HeroBanner.css";

const HeroBanner = (props) => {
  return (
    <div className='video-outer-wrap'>
       
        <div className='video-inner-wrap'>
        <Container>
        <div className='content'>
          {/* <h1>
            <span dangerouslySetInnerHTML={{ __html: props.data.text.line1 }}></span><br/>
            <span className="mid-section"><TextRotator data={props.data.text.magicline}/></span>
            <br/>
            <span className="bottom-section">
            {props.data.text.line3}
            </span>
          </h1> */}
          </div>
        </Container>
            <video  loop playsInline autoPlay="autoplay" muted><source src={props.data.video} type="video/mp4" /></video>
        </div>
        
    </div>
  );
};

export default HeroBanner;
