import React,{ useEffect } from "react";
import { connect } from "react-redux";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import homepageData from '../assets/data/homepage.json'
import HeroBanner from '../components/Home/HeroBanner'
import Projects from '../components/Home/Projects'

import { Link } from 'react-router-dom'
import './Home.scss' ;

const Home = (props)=> {
    const { dispatch} = props;
    useEffect(()=>{

       
  
      })

    return (
      <> 
      <HeroBanner data={homepageData.hero} />
      <Container>
       
            <Row className="intro-blurb">
              <Col md={6}>
              <h4>Hello I’m Helen</h4>
              </Col>
              <Col md={6}>
              <div className="home-page-text-intro"  dangerouslySetInnerHTML={{ __html:homepageData.text}}></div>
              </Col>
            </Row>
            
        
      
      </Container>
      <Projects/>
      <Container>
      <div className="link-item">
        <h3>{homepageData.about.label}</h3>
                    
        <Link className="view-more-button" to={`${homepageData.about.to}`}>VIEW</Link>
        <Link to={`${homepageData.about.to}`}><img src={homepageData.about.img} /></Link>
      </div>
      <div className="link-item">
        <h3>{homepageData.portfolio.label}</h3>
                      
        <Link className="view-more-button" to={`${homepageData.portfolio.to}`}>VIEW</Link>
        <Link to={`${homepageData.portfolio.to}`}><img src={homepageData.portfolio.img} /></Link>
      </div>
      </Container>
     
      </>
    )
}

function mapStateToProps(state){
	return {	}
}

export default connect(mapStateToProps)(Home);