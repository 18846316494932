import React, {useRef, useState} from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as EmailValidator from 'email-validator';
import axios from 'axios'

import './ContactUs.scss';

const ContactUs = (props) => {
  const form = useRef(null);
  const [formValid,setFormValidVal] = useState(false);

  const [nameVal,setNameVal] = useState("");
  const [nameValid,setNameValid] = useState(false);
  const [nameValidationMessage,setNameValidationMessage] = useState("");

  const [emailVal,setEmailVal] = useState("");
  const [emailValid,setEmailValid] = useState(false);
  const [emailValidationMessage,setEmailValidationMessage] = useState("");

  const [messageVal,setMessageVal] = useState("");
  const [messageValid,setMessageValid] = useState(false);
  const [messageValidationMessage,setMessageValidationMessage] = useState("");

  const [messageSent, setMessageSent] = useState(false);
  const [messageBusy, setMessageBusy] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
const [successMessage, setSuccessMessage] = useState("Your Message has been sent, We'll be in touch!");
  const [errorMessage, setErrorMessage] = useState("We Could not send your message, please try again later");
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const formSubmit = async(e)=>{
   e.preventDefault();
   const data = new FormData(form.current);
    console.log(data);
    const instance = axios.create({headers: {'Content-Type': 'multipart/form-data'}});
    setMessageBusy(true);
    const response = await instance.post('https://helencope.com/mail.php',{name:nameVal,email:emailVal,message:messageVal})
            .catch(err=>{           
              setMessageBusy(false);
              setErrorMessage("Server Error, your message could not be sent -  please try again.");
              setShowErrorMessage(true)
            })
    
    
    console.log({response})
    setMessageBusy(false);
    setMessageSent(true);

    if(response){
      if(response.data.success){
        setShowSuccessMessage(true);
      } else {
        setErrorMessage(response.data.error)
        setShowErrorMessage(true);
      }
    }

  };

  const resetPage = async()=>{
    setNameVal("")
    setEmailVal("")
    setMessageVal("")
    setMessageSent(false);
    setMessageBusy(false);
    setShowErrorMessage(false)
    setShowSuccessMessage(false)
    setSuccessMessage("Your Message has been sent, We'll be in touch!")
    setErrorMessage("We could not send your message, please try again later");
  }

  const evaluateName = async()=>{
    if(!nameVal){
      setNameValid(false);
      setNameValidationMessage("You must supply a name.")
      
    } else {
      setNameValid(true)
      setNameValidationMessage("")
    }
  }
  const evaluateEmail = async()=>{
    if(!emailVal){
      setEmailValid(false);
      setEmailValidationMessage("You must supply an email address.")
      
    } 
    const isValid =  EmailValidator.validate(emailVal);
    if(!isValid){
      setEmailValid(false);
      setEmailValidationMessage("You must supply a valid email address.")
    } else {
      setEmailValid(true);
      setEmailValidationMessage("")
    }
    
  }


  const evaluateMessage = async()=>{
    if(!messageVal){
      setMessageValid(false);
      setMessageValidationMessage("You must supply a message.")
      
    } else {
      setMessageValid(true);
      setMessageValidationMessage("")
    }
  }

  const evaluateForm = async()=>{
    if(!nameVal){
      setNameValid(false);
      setNameValidationMessage("You must supply a name.")
      
    } else {
      setNameValid(true)
      setNameValidationMessage("")
    }


     if(!emailVal){
      setEmailValid(false);
      setEmailValidationMessage("You must supply an email address.")
      
    } 
    const isValid =  EmailValidator.validate(emailVal);
    if(!isValid){
      setEmailValid(false);
      setEmailValidationMessage("You must supply a valid email address.")
    } else {
      setEmailValid(true);
      setEmailValidationMessage("")
    }


     if(!messageVal){
      setMessageValid(false);
      setMessageValidationMessage("You must supply a message.")
      
    } else {
      setMessageValid(true);
      setMessageValidationMessage("")
    }

    if(!nameValid || !emailValid || !messageValid){
      setFormValidVal(false)
      return false;
    } else {
      setFormValidVal(true)
      return true;
    }
   
  };

  return (
    <section className="contact-us">
      <Container>
        <Row>
          <Col><h1>Lets discuss your<br/> project</h1></Col>
          {/* <pre>
            {JSON.stringify({nameVal, nameValid, nameValidationMessage, formValid},null,2)}
          </pre> */}
        </Row>
        <Row>
          <Col md={9}>
            {!messageSent ? (
            <Form onSubmit={formSubmit} ref={form}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Your Name</Form.Label>
                <Form.Control type="text" value={nameVal} placeholder="Enter Name" onChange={(e)=>{console.log(e.target.value);setNameVal(e.target.value  )}} onBlur={(e)=>{ console.log('blurred name'); evaluateName()}} />
                { (!nameValid ) && (
                  <div className="validation-message validation-message-name">
                    <p>{nameValidationMessage}</p>
                  </div>
                )}
              </Form.Group>
              
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control value={emailVal || ""} type="email" placeholder="Enter email" onChange={(e)=>{setEmailVal(e.target.value)}} onBlur={(e)=>{ console.log('blurred name'); evaluateEmail()}} />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
                { (!emailValid ) && (
                  <div className="validation-message validation-message-email">
                    <p>{emailValidationMessage}</p>
                  </div>
                )}
              </Form.Group>
        
              <Form.Group className="mb-3" controlId="message">
                <Form.Label>Describe the Project</Form.Label>
                <Form.Control value={messageVal || ""} as="textarea" placeholder="Describe the Project" onChange={(e)=>{setMessageVal(e.target.value);evaluateMessage()}} onBlur={evaluateMessage} />
                { (!messageValid ) && (
                  <div className="validation-message validation-message-message">
                    <p>{messageValidationMessage}</p>
                  </div>
                )}
              </Form.Group>
              {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="I agree with the privacy policy" />
              </Form.Group> */}
              {showErrorMessage && (
                <div className="form-error"><p>{errorMessage}</p></div>
              )}
              
              <Button disabled={!nameValid || !emailValid || !messageValid} variant="primary" type="submit">
                Discuss Project
              </Button>
            </Form>
            ):(
              <>
              {showErrorMessage && (
                <div className="form-error"><p>{errorMessage}</p></div>
              )}
              {showSuccessMessage && (
                <div className="form-success"><p>{successMessage}</p></div>
              )}
              
              <Button variant="primary" onClick={(e)=>{e.preventDefault(); resetPage();}}>
                Reset Contact Form
              </Button>
             
              </>
            )}
          </Col>
          <Col md={3}>
            <div className="aside-wrap">
              <div className="aside-section">
                <h2>Follow Me</h2>
                <p>instagram: <a rel="noreferrer" target="_blank" href="https://www.instagram.com/hcopedesigns/">@hcopedesigns</a></p> 
                <p>linked in: <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/helen-cope/">@helen-cope</a></p>
              </div> 
            </div>          
          </Col>
        </Row>
      </Container>
       

        

    </section>
  );
};

export default ContactUs;
