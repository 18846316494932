import React, { useState, useRef, useEffect} from "react";
import { Link } from 'react-router-dom' 
import Nav from 'react-bootstrap/Nav';
import Button from "react-bootstrap/Button";
import {gsap} from 'gsap'

import "./CustomMenu.scss";

const CustomMenu = (props) => {
    const {menus, hamburger} = props;

    const gs = useRef(null);
    const toggle = useRef(null);
    const menu = useRef(null);
   
    

    const[menuVisible,setVisibility] = useState(false)
    
    const menuToggle = ()=>{
        setVisibility(!menuVisible)
        
    }

    

    useEffect(()=>{

        let ctx = gsap.context(()=>{
            
            const tl = gsap.timeline({paused:true})

            tl.to('.custom-menu',{
                duration:0,
                display:'block',
                ease:'Expo.easeInOut'
            })

            tl.from('.menu-bg span',{
                duration:1,
                x:"100%",
                stagger:0.1,
                ease:'Expo.easeInOut'
            })
            tl.from('.main-menu li a',{
                duration:1.5,
                y:"100%",
                stagger:0.2,
                ease:'Expo.easeInOut'
            },"-=0.5")
            tl.reverse();
            // tl.reverse()
            toggle.current.addEventListener('click',()=>{
            
                tl.reversed(!tl.reversed())
            })
            menu.current.addEventListener('click',()=>{
            
                tl.reversed(!tl.reversed())
            })
        },gs)
        return ()=>ctx.revert();
    },[])

  return (
    <div className="custom-menu-outer" ref={gs}>
        <Button ref={toggle} className="custom-menu-toggle"  onClick={menuToggle}><img className="menuToggle" src={hamburger} alt="menu toggle"/></Button>
        {/* <pre>{JSON.stringify(props,null,2)}</pre> */}
        <div  className={`custom-menu ${menuVisible?'custom-menu-visible':'custom-menu-hidden'}`}>
            <div className='custom-menu-inner'>
                <div className="menu-bg">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
               
                    <Nav as="nav" className="main-menu-wrap">
                        <ul className="main-menu" ref={menu}>
                            { menus.map((data,index) => (
                                <li key={index}><Link key={index}  to={data.links}>{data.name}</Link></li>
                            ))}
                        </ul>
                    </Nav>
                </div>
                
            
        </div>  
    </div>
  );
};

export default CustomMenu;
