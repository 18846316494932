import { useLayoutEffect,useRef,useEffect } from 'react';
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


const TallScroller = (props) => {
    const tallScroller = useRef();


    useLayoutEffect(()=>{

        let ctx = gsap.context(() => {
            gsap.registerPlugin(ScrollTrigger); 

            
            gsap.to('.tall-scroller-inner', { // <- selector text, scoped to this component!
                opacity: 1,
                y: "-50%",
                ease: "inOut",
                duration: 0.2,
                stagger: 0.04,
                scrollTrigger: {

                    trigger: ".tallScroller",
                    scrub: true
                  },
              });

            return;

        },tallScroller)
        return () => ctx.revert();

    },[])

    return  (
        <Container ref={tallScroller}>
        <div className={`tallScroller image-tile ${props.data.bordered?'bordered':'un-bordered'}`}>
        <Row>
        <Col md={12} className='tall-scroller-wrapper' style={{height:props.data.height}}>
         <div className='tall-scroller-inner'> 
         {props.data.items.map((item, i)=>
          
             <>
              
                 {item.label && (
                   <h3>{item.label}</h3>
                 )}
               <img src={item.img} alt={item.alt} title={item.alt} />
               {item.caption && (
                   <div className="image-title-caption" dangerouslySetInnerHTML={{ __html: item.caption }}>
                   </div>
                 )}
               
               </>
           
         )}
         </div>
          </Col>
         </Row>
         </div>
         </Container>
    )
}


export default TallScroller;