import React from "react";

import AboutUs from "../components/About/AboutUs";

const About = (props)=> {
    return (
      
            <AboutUs />
       
    )
}



export default About;