import React from "react";

import CaseStudyIndex from "../components/CaseStudy/CaseStudyIndex";

const CaseStudiesIndex = (props)=> {
    return (
            <>
            <CaseStudyIndex {...props} />
            </>
            
        
    )
}



export default CaseStudiesIndex;