import React,{useRef, useEffect} from "react";
import {gsap} from 'gsap'
import {CustomEase} from 'gsap/CustomEase'
// import {CustomWiggle} from 'gsap/CustomWiggle'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './SplashPanel.scss';


const SplashPanel = (props) => {

  const gs = useRef(null);
  const {data} = props;

  

  const clickItem = (href)=>{
    window.open(href, "_blank")
  }

  useEffect(()=>{

   

    let ctx = gsap.context(()=>{
      gsap.registerPlugin(CustomEase);
      //gsap.registerPlugin(CustomWiggle);
        gsap.registerPlugin(ScrollTrigger);

        //CustomWiggle.create("myWiggle", {wiggles: 2});
        //CustomWiggle.create("funWiggle", {wiggles: 5, type: "anticipate"});
        gsap.from(".splash-title-1", {

            x: "-100%",

            scrollTrigger: {
              trigger: ".splash-title-1",
              scrub: true
            }
          })


          gsap.from(".splash-title-2", {

            x: "15%",

            scrollTrigger: {
              trigger: ".splash-title-2",
              scrub: true
            }
          })

          gsap.to(".splash-image", {duration: 1, rotation: 15, ease: "funWiggle"});

          gsap.to(".splash-image", {duration: 1, rotation: 15, ease: "funWiggle",scrollTrigger: {
            trigger: ".splash-image",
            scrub: true
          }});

    },gs);

    return ()=>ctx.revert();
  },[])
  return (
    <section className={`splash-panel ${data.tags?'has-tags':'no-tags'}`} style={{background: data.background}} ref={gs}>
       
       <h1 className="splash-title-1" style={{color: data.textColor}}>{data.line1+' '+data.line1+' '+data.line1+' '+data.line1+' '+data.line1+' '+data.line1+' '+data.line1}</h1>
       { data.img && (
          <center><img src={data.img} className="splash-image" /></center>
       )}
       <h1 className="splash-title-2" style={{color: data.textColor}}>{data.line2+' '+data.line2+' '+data.line2+' '+data.line2+' '+data.line2+' '+data.line2+' '+data.line2}</h1>
        {
          data.tags &&(

          
        <div className="tags">
          <Container>
            <Row>
            <Col md="4">
              <dl>
                <dt>Services</dt>
                { data.tags.services.map((tag)=>
                <dd>{tag}</dd>
                )}
              </dl>
            </Col>
            <Col  md="4">
            <dl>
                <dt>Industry</dt>
                { data.tags.industry.map((tag)=>
                <dd>{tag}</dd>
                )}
              </dl>
            </Col>
            <Col  md="4">
            <dl>
                <dt>Year</dt>
                <dd>{data.tags.year}</dd>
              </dl>
            </Col>
            </Row>
          </Container>
        </div>
        )
      }
    </section>
  );
};

export default SplashPanel;
