import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './Carousel.scss';

export default class SimpleSlider extends Component  {
  render() {
    return (
        <Slider {...this.props.settings}>
          { this.props.items.map((item)=>
            <div className="slider-cell">
                <img src={item.img} alt={item.alt} title={item.alt}/>
            </div>
          )}
        </Slider>
    );
  }
}
