import { useLayoutEffect,useRef,useEffect } from 'react';

import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from 'split-type'
import './TextRotateIn.css'

const TextRotateIn = (props) => {
    const gs = useRef();


    useEffect(()=>{

   

        let ctx = gsap.context(()=>{
            gsap.registerPlugin(ScrollTrigger);
    
            gsap.from(".word", {
                
                y: 0,
                rotationX:-90,
                scrollTrigger: {
                  trigger: ".word",
                  scrub: false,
                },
                stagger:.2
              })
    
    
           
        },gs);
    
        return ()=>ctx.revert();
      },[])

    return  (
        <span ref={gs} >
            <span className="word" dangerouslySetInnerHTML={{ __html:props.data}}></span>
        </span>
    )
}


export default TextRotateIn;