import React from "react";

import CaseStudies from "../components/CaseStudy/CaseStudies";

const CaseStudy = (props)=> {
    return (
            <>
            <CaseStudies {...props} />
            </>
            
        
    )
}



export default CaseStudy;