import React from "react";
import './CaseStudies.scss';
import SplashPanel from './../portfolio/SplashPanel'
import Container from "react-bootstrap/Container";
import { Link } from 'react-router-dom' 

import PortfolioBlock from './PortfolioBlock'
const CaseStudies = (props) => {



  const SplashData = {
      line1:props.headline.line1,
      line2:props.headline.line2,
      img:props.headline.img,
      background:props.headline.background,
      textColor:props.headline.textColor
    }

  return (
    <section className="case-study">
      <SplashPanel data={props.headline}/>
      {/* item rows */}
      {
        props.rows && (
          <>
          {props.rows.map((rowData,i)=>
            <div key={i}>
              <PortfolioBlock row={rowData} />
            </div>
          )}
          </>
        )
      }

      {/* end item rows */}
      <Link className="bigNextLink" to={`/portfolio/${props.nextLinkSlug}`}>
      <Container>
        <center>
          <span className="bigNextButton">NEXT WORK</span>
        </center>
      </Container>
      </Link>
     
    </section>
  );
};

export default CaseStudies;
