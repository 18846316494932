import React , { useState , useEffect } from 'react';
import { Link } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Staff from './../components/Home/Staff';
import ContactLink from './../components/Home/ContactLink';
import TextRotator from "./../components/Home/TextRotator"
import './Footer.css'
import data from './../assets/data/footer-data'

import img1 from './../assets/images/helen.svg'

const Footer = () => {
   

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };

    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };

      window.addEventListener("scroll", toggleVisibility);

      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);
  return (
            <div>
             
            <footer className="clearfix bg-style bg-body-success footer">


                    <ContactLink data={data.contactLink} />
                    <Container className="footer-main">
                        {/* <Row>
                            <Col>
                                <Navbar.Brand><Link to="/"><span className="logo-text">Grotesque</span></Link></Navbar.Brand>
                                <p className="sub-widget-logo">
                                Design, at your service.
                                </p>
                            </Col>
                            
                        </Row> */}
                        <Row className="footer-main-links">
                          <Col sm="6" md="4">
                          <h4>SITEMAP</h4>
                            <ul className="link-list link-list-sitemap">
                              { data.links.site.map((link,i)=>
                                <li  key={i}>
                                <Link to={link.href}>{link.label}</Link>
                                </li>
                              )

                              }
                            </ul>
                          </Col>
                          <Col  sm="6" md="4">
                          <h4>Socials</h4>
                            <ul className="link-list link-list-socials">
                              { data.links.socials.map((link,i)=>
                                <li  key={i}>
                                <Link rel="noreferrer" target="_blank" to={link.href}>{link.label}</Link>
                                </li>
                              )

                              }
                            </ul></Col>
                          <Col className="hidden-xs" sm="0" md="4">
                            &nbsp;
                          </Col>
                          
                        </Row>
                        <Row>
                          <Col><p>{data.splash.role}</p></Col>
                          <Col><p className="text-right">{data.splash['site-type']}</p></Col>
                        </Row>
                        <Row>
                         <img src={img1} />
                        </Row>
                    
                   
                
                </Container>
                
            </footer>
            
            {
                isVisible &&
                <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
            }
            </div>
  );
};

export default Footer;
