const menus = [
    {
        id: 1,
        name: 'Home',
        namesub: [
        ],
        links: '/'
    },
    {
        id: 3,
        name: 'About',
        namesub: [
        ],
        links: '/about'
    },
    {
        id: 4,
        name: 'Portfolio',
        namesub: [
        ],
        links: '/portfolio'
    },
    {
        id: 2,
        name: 'Contact',
        namesub: [
        ],
        links: '/contact'
    }   

]

export default menus;
