import React  from 'react';
import { Link } from 'react-router-dom' 

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar,{Offcanvas} from 'react-bootstrap/Navbar';
import CustomMenu from './CustomMenu'

import menus from '../pages/menu';

import './Header.css'
import logo from '../assets/images/logo.png'
import hamburger from '../assets/images/hamburger.png'


const Header = (props) => {
   

    return (
        <header id="header_main">
            <Navbar bg="transparent" data-bs-theme="dark" expand={false} as="div">
                <Container>
                    <Navbar.Brand>
                        <Link to="/">
                        <img id="logo_header" className='logo' src={logo} srcSet={logo} alt="Helen Cope Design House" />
                        {/* <span className="logo-text brand">Helen Cope</span>    */}
                        </Link>
                    </Navbar.Brand>
                    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" >
                        <img src={hamburger} alt="menu toggle"/>
                    </Navbar.Toggle>
                    <Navbar.Offcanvas placement="end" data-bs-theme="dark" className="justify-content-end" >
                        
                        <Nav className="nav-bar-custom">
                            { menus.map((data,index) => (
                                <Link key={index}  to={data.links}>{data.name}</Link>
                            ))}
                        </Nav>
                    </Navbar.Offcanvas> */}

                    <CustomMenu {...{menus,hamburger}} />

                    {/* <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                        Some text as placeholder. In real life you can have the elements you
                        have chosen. Like, text, images, lists, etc.
                        </Offcanvas.Body>
                    </Offcanvas> */}
                   
                </Container>
            </Navbar>
        </header>
    )
}

export default Header;