import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from 'react-router-dom'

import Card from 'react-bootstrap/Card';

import "./Projects.scss";
import portfolio from './../../assets/data/portfolio'
const Projects = (props) => {
  return (
    <section className="projects">
        <Container>
            {/* <pre>{JSON.stringify(props.data.full,null,2)}</pre> */}
        
            {portfolio.items.filter((item)=>item.name !=="demo" && item.name !=="portfolio").slice(0,1).map((project,i)=>
                <Row key={i}>
                    <Col>
                        <div className="project-item project-item-full">
                            <Link to={`/portfolio/${project.slug}`}>
                                <h2>{project.name}</h2>
                            </Link>
                            <Link  to={`/portfolio/${project.slug}`}>
                                <img src={project.splashImg} />
                            </Link>
                            <p>{project.blurb}</p>
                            <Link className="view-more-button" to={`/portfolio/${project.slug}`}>VIEW</Link>
                        </div>
                    </Col>
                </Row>
            )} 
             <Row>
                {portfolio.items.filter((item)=>item.name !=="demo"&& item.name !=="portfolio").slice(1).map((project,i)=>
                <Col xs="12" sm="12" md="6"  key={i}>
                    <div className="project-item project-item-full">
                            <Link to={`/portfolio/${project.slug}`}>
                                <h2>{project.name}</h2>
                            </Link>
                            <Link  to={`/portfolio/${project.slug}`}>
                                <img src={project.splashImg} />
                            </Link>
                            <p>{project.blurb}</p>
                            <Link className="view-more-button" to={`/portfolio/${project.slug}`}>VIEW</Link>
                        </div>
                </Col>
                )} 
            </Row>
{/*

            {props.data.full.slice(1).map((project,i)=>
            <Row  key={i}><Col>
                <div className="project-item project-item-full" style={{backgroundColor:project.bg}}>
                    <h2>{project.name}</h2>
                </div></Col>
            </Row>
            )} */}
            

        

        </Container>
        
    </section>
  );
};

export default Projects;
