import { useLayoutEffect,useRef,useEffect } from 'react';
import kebabCase from 'just-kebab-case';
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SplitType from 'split-type'
import './Filmstrip.scss'

const FilmStrip = (props) => {
    const filmstrip = useRef();


    useLayoutEffect(()=>{
      
        let ctx = gsap.context(() => {
          if (props.data.animated){
            gsap.registerPlugin(ScrollTrigger); 

            
            gsap.to('.film-strip-inner', { // <- selector text, scoped to this component!
                opacity: 1,
                x: props.data.offset,
                ease: "inOut",
                duration: 0.2,
                stagger: 0.04,
                scrollTrigger: {

                    trigger: ".filmstrip",
                    scrub: true
                  },
              });
            }
                return;

        },filmstrip)
      
        return () => ctx.revert();

    },[])

    return  (
        <Container ref={filmstrip}>
        <div className={`filmstrip image-tile ${props.data.bordered?'bordered':'un-bordered'}`}>
        <Row>
        <Col md={12} className='film-strip-wrapper' >
         <div className='film-strip-inner'> 
         {props.data.items.map((item, i)=>
          
             <>
              
                 {item.label && (
                   <h3>{item.label}</h3>
                 )}
                { item.img.includes('.mp4')? (
                  <video  playsInline loop  autoPlay="autoplay" muted><source src={item.img} type="video/mp4" /></video>
                ):(
                  <img src={item.img} alt={item.alt} title={item.alt} />
                ) }
               
               
               {item.caption && (
                   <div className="image-title-caption" dangerouslySetInnerHTML={{ __html: item.caption }}>
                   </div>
                 )}
               
               </>
           
         )}
         </div>
          </Col>
         </Row>
         </div>
         </Container>
    )
}


export default FilmStrip;